
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Unna:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap');

.max-content {
  max-width: 1482px;
}

.acilis {
  -webkit-animation-name: acilis;
          animation-name: acilis;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards
}
@-webkit-keyframes acilis {
  0% {
    opacity: 0%;

  }
  100% {
    opacity: 100%;

  }
}
@keyframes acilis {
  0% {
    opacity: 0%;

  }
  100% {
    opacity: 100%;

  }
}
.logo-acil {
  -webkit-animation-name: logo-acil;
          animation-name: logo-acil;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards
}

@-webkit-keyframes logo-acil {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

@keyframes logo-acil {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}
.satir {
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.gear {
  -webkit-animation-name: cark;
          animation-name: cark;
  -webkit-animation-fill-mode: none;
          animation-fill-mode: none;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

@-webkit-keyframes cark {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

@keyframes cark {
  0% {
    rotate: 0deg;
  }

  100% {
    rotate: 360deg;
  }
}

.hamopen {
  width: 100%;
  height: 100%;
  -webkit-animation-name: ham-back;
          animation-name: ham-back;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  position: fixed;
  z-index: 2000;
  position: absolute;
}
.hamopen-opac{
  position: fixed;
  height: 100vh;
  z-index: 999;
  width: 100%;
 background-color: rgba(0, 0, 0, 0.6);
}
.font-nav {
  text-decoration: none;
}

@-webkit-keyframes ham-back {
  0% {
    display: none;
  }

  100% {
    display: -webkit-box;
    display: flex;
  }
}

@keyframes ham-back {
  0% {
    display: none;
  }

  100% {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.scroll-disable {
  overflow: hidden;
}

.hamclose-opac {
  background-color: rgb(0, 0, 0, 0);
  -webkit-transition-duration: 1s;
       -o-transition-duration: 1s;
          transition-duration: 1s;

}

a{
  text-decoration: none !important;

}

.hamclose {
  width: 100%;
  height: 100%;
  display: none;
  z-index: 2000;
  -webkit-animation-name: ham-forw;
          animation-name: ham-forw;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.acilis-ke {
  -webkit-animation: aciliyor;
          animation: aciliyor;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes aciliyor {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

@keyframes aciliyor {
  0% {
    opacity: 0%;
  }

  100% {
    opacity: 100%;
  }
}

.kapanis-ke {
  -webkit-animation: kapaniyor;
          animation: kapaniyor;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes kapaniyor {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}

@keyframes kapaniyor {
  0% {
    opacity: 100%;
  }

  100% {
    opacity: 0%;
  }
}
.mybg{
  background-image: url("../public/Images/mybg.png");
 background-repeat: no-repeat;
 background-size:cover;
}


/* .opacgo {
  animation: acilopac;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: rgb(0, 0, 0);
}

@keyframes acilopac {
  0% {
    background-color: rgba(0, 0, 0, .5);
  }

  100% {
    background-color: rgb(0, 0, 0);
  }
}

.opacback {
  animation: kapanopac;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  background-color: rgb(0, 0, 0);
}

@keyframes kapanopac {
  0% {
    background-color: rgb(0, 0, 0);
  }

  100% {
    background-color:rgba(0, 0, 0, 0.5)
  }
} */


@-webkit-keyframes ham-forw {
  0% {
    display: -webkit-box;
    display: flex;

  }

  100% {

    display: none;
  }
}


@keyframes ham-forw {
  0% {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

  }

  100% {

    display: none;
  }
}

.ham-ul {
  background-color: rgb(36, 36, 36);
  color: white;
}


.list-open {
  -webkit-animation-name: list-open;
          animation-name: list-open;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 1000;
  background-color: #121519;
}

.list-close {
  -webkit-animation-name: list-close;
          animation-name: list-close;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  z-index: 1000;
  background-color: #121519;
}

@-webkit-keyframes list-open {
  0% {
    height: 0;
    width: 100%;
    opacity: 100%;
  }

  100% {
    height: 70vh;
    width: 100%;
    opacity: 100%;
  }
}

@keyframes list-open {
  0% {
    height: 0;
    width: 100%;
    opacity: 100%;
  }

  100% {
    height: 70vh;
    width: 100%;
    opacity: 100%;
  }
}

@-webkit-keyframes list-close {
  0% {
    height: 70vh;
    width: 100%;
    opacity: 100%;
  }

  100% {
    height: 0;
    width: 100%;
    opacity: 100%;
  }
}

@keyframes list-close {
  0% {
    height: 70vh;
    width: 100%;
    opacity: 100%;
  }

  100% {
    height: 0;
    width: 100%;
    opacity: 100%;
  }

}



.list1 {
  -webkit-animation-name: list;
          animation-name: list;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  width: 0;
}

.list2 {
  -webkit-animation-name: list;
          animation-name: list;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  width: 0;
}

.list3 {
  -webkit-animation-name: list;
          animation-name: list;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  width: 0;
}

.list4 {
  -webkit-animation-name: list;
          animation-name: list;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  width: 0;
}

.list5 {
  -webkit-animation-name: list;
          animation-name: list;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  width: 0;
}

.list6 {
  -webkit-animation-name: list;
          animation-name: list;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  opacity: 0;
  width: 0;
}

@-webkit-keyframes list {
  0% {}

  100% {
    opacity: 100%;
    width: 80%;

  }
}

@keyframes list {
  0% {}

  100% {
    opacity: 100%;
    width: 80%;

  }
}

.listcl {
  -webkit-animation-name: listcl;
          animation-name: listcl;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes listcl {
  0% {}

  100% {
    opacity: 0%;
  }
}

@keyframes listcl {
  0% {}

  100% {
    opacity: 0%;
  }
}

.myborder-1 {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  -o-border-image: -o-linear-gradient(left, #121519, #12151900);
     border-image: -webkit-gradient(linear, left top, right top, from(#121519), to(#12151900));
     border-image: linear-gradient(90deg, #121519, #12151900);
  border-image-slice: 1;
  border-radius: 10px;
  /* will have no effect */
}

.myborder-2 {
  border-bottom: 0;
  border-top: 0;
  -o-border-image: none;
     border-image: none;
}


.fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.fadeInNoOpacity {
  -webkit-animation-name: fadeInNoOpacity;
          animation-name: fadeInNoOpacity;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}


@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
            transform: translateY(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-40px);
            transform: translateX(-40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes fadeInNoOpacity {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes fadeInNoOpacity {
  0% {
    -webkit-transform: translateX(40px);
            transform: translateX(40px);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.text-lib {
  position: absolute;
  z-index: 0;
  opacity: 0%;
  scale: 0%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
  color: white;
}

.img-lib:hover>.text-lib {
  position: absolute;
  z-index: 2;
  opacity: 100%;
  scale: 130%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.img-lib:hover>.img-lib {
  scale: 150%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.img-lib:not(:hover)>.img-lib {
  scale: 100%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.hide-lib {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  background-color: #121519;
  opacity: 0%;
}

.img-lib:hover>.hide-lib {
  background-color: #121519;
  opacity: 70%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}


.img-lib:not(:hover)>.hide-lib {
  background-color: #121519;
  opacity: 0%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.activee {
  /* color: #720000 !important; */
  border-bottom: 2px solid;
  /* color: #718aad !important; */
}

.activeeFoot {
  border-bottom: 1px solid white;
}

.activee-2 {
  /* border-bottom: 2px solid white; */
  color: wheat !important;
}



.nonactive {
  color: #121519;
}

.nonactive-2 {
  color: white;
}

.nonactiveham {
  color: white;
}

.slideactivee {
  color: white !important;
  border-bottom: 1px solid white;
}

.over-hid {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.over-hid-single {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-part:hover>.blog-part-top>.blog-part-img {
  background-color: black;
  opacity: 50%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}

.blog-part:not(:hover)>.blog-part-top>.blog-part-img {
  opacity: 100%;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s;
}