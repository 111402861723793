.top{
    overflow: hidden;
    object-fit: cover;
    margin: 0;
}
.bot{
    overflow: hidden;
    object-fit: cover;
    width: 10rem;
    height: 10rem;
    margin: 0;
}