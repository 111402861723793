

.swiper {
  width: 95vw;
  height: fit-content;
display: flex !important;
justify-content: center !important;
align-items: center !important;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding-top:20px !important;
padding-bottom: 50px !important;
display: flex !important;
justify-content: center !important;
align-items: center !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.swiper-button-prev{
  z-index: 999;
  position: absolute !important;
}
.swiper-button-next{
  z-index: 999;
  position: absolute !important;
}
